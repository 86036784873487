<template>
    <!-- 素材列表页 -->
    <div class="reportTemplateFile">
        <el-card>
        <!-- 搜索框 -->
        <div class="div-height div">
            <div class="left">
                <el-button
                    class="themeButton"
                    @click="handleOpen('add', '添加附件')"
                    >添加附件</el-button
                >
            </div>
            <div class="expSearch">  
                <el-input
                    clearable
                    placeholder="请输入内容"
                    v-model="input"
                    @clear="searchContent">
                    <el-button slot="append" class="el-icon-search" @click="searchContent" ></el-button>
                </el-input>
            </div>
        </div>
        <!-- 表格 -->
        <div class="div">
            <el-table :data="tableData" style="width: 100%">
                <!-- 排序 -->
                <el-table-column type="index" label="序号" width="180" align="center"></el-table-column>
                <!-- 附件名称 -->
                <el-table-column prop="filename" label="附件名称"></el-table-column>
                <!-- 所属报告模板名称 -->
                <el-table-column prop="name" label="所属报告模板名称"></el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="operateItem">
                            <div class="operatorItem floatLeft" @click="handleOpen('download','下载附件',scope.$index,scope.row)">下载附件</div>
                            <div class="operatorItem floatLeft" @click="handleShare(scope.row)">共享</div>
                            <div class="operatorItem floatLeft" @click="handleOpen('update','重命名',scope.$index,scope.row)">重命名</div>
                            <div class="operatorDel floatLeft" @click="handleOpen('delete','删除附件',scope.$index,scope.row)">删除附件</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="div-height padding-top div">
            <el-pagination
                @current-change="handleCurrentChange"
                background
                layout="prev, pager, next"
                :current-page.sync="page"
                :total="dataTotal"
                v-if="dataTotal != 0"
            >
            </el-pagination>
        </div>
</el-card>
        <el-dialog
            title="共享弹窗"
            :visible.sync="dialogShareVisible"
            width="30%"
        >
            <span>
                <span style="font-size: 0.8333vw;">请选择要共享的同事：</span><br><br>
                <el-select v-model="selectTogether" multiple placeholder="请选择" style="width:100%;">
                    <el-option
                    v-for="item in togetherList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </span>
            <span
                slot="footer"
                class="dialog-footer"
                v-if="handleSign != 'is_qrcode'">
                <el-button class="themecancelButton" @click="dialogShareVisible = false">取 消</el-button>
                <el-button class="themeButton" @click="dialogShareFix()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            :width="dialogWidth"
        >
            <span>
                <span
                    v-if="handleSign == 'add'"
                >
                    <el-input
                        v-model="filename"
                        placeholder="请输入附件名称"
                    ></el-input>
                    <br>
                    <br>
                    <el-select
                        style="width:100%"
                        v-model="report_template_id"
                        clearable
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入报告模板名称搜索！"
                        :remote-method="reportTemplateRemoteMethod"
                        @clear="reportTemplateSelectClear()"
                        @change="vistualExpSelectChange()"
                        :loading="loading">
                        <el-option
                            v-for="item in reportTemplateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <br><br>
                    <div>
                        <el-upload
                            class="upload-demo"
                            :data = {id:report_template_id}
                            :action="uploadUrl"
                            :on-success="markHandleAvatarSuccess"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            :limit="1"
                            :on-exceed="handleExceed"
                            :before-upload="markHandleBeforeUpload"
                            :file-list="fileList">
                            <el-button size="small" type="primary">点击上传文件</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
                        </el-upload>
                    </div>
                </span>
                <span
                    v-else-if="handleSign == 'update'"
                >
                    <el-input
                        v-model="filename"
                        placeholder="请输入附件名称"
                    ></el-input>
                </span>
                <span v-else class="dialog-span">您确定要删除么？</span>
            </span>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFix()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/report/reportTemplateFile.scss";
</style>
<style>
.reportTemplateFile .el-table__body-wrapper{
    height: 60vh !important;
    overflow: scroll!important;
    scrollbar-width: none!important;
    -ms-overflow-style: none!important;
}
.reportTemplateFile .el-table__body-wrapper::-webkit-scrollbar{
    display: none !important
}
.reportTemplateFile .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
.reportTemplateFile .el-table__row{
    height: 7vh
}
.reportTemplateFile .el-dialog{
    border-radius: 1vw;
}
.reportTemplateFile .el-dialog__body{
    padding: 1.5625vw 1.0417vw ;
    padding-top: 0.2604vw !important;
    color: #606266;
    font-size: 0.7292vw;
    word-break: break-all;
    padding-bottom: 1.5625vw;
    /* text-align: center; */
}
.reportTemplateFile .el-table{
    font-size: 0.8333vw !important;
}
</style>
<script>
import questionBank from "@/assets/js/teacher/report/reportTemplateFile.js";
export default {
    ...questionBank
};
</script>
