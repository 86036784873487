import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { sleep, startLoading, endLoading } from "@/utils/function"; 
import API from "@/api/teacher/report/reportTemplateFile";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "other",
    components: {},
    data() {
        return {
            input: "",
            page: 1,
            dataTotal: 0,
            loading: "",
            dialogVisible: false,
            tableData: [],
            reportTitle: "",
            report_template_id: "",
            handleSign: "",
            dialogTitle: "",
            filename:'',
            file_url:'',
            file_path:'',
            report_template_id:'',
            report_template_file_id:'',
            uploadUrl: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/report/uploadReportTemplateFile",
            fileList:[],
            options: [],
            reportTemplateList:[],
            loading:false,
            dialogWidth:'30%',

            togetherList:[],
            selectTogether:[],
            id:0,
            dialogShareVisible:false,
        };
    },
    created() {
        const self = this;
        self.getData();
    },
    methods: {
        dialogShareFix()
        {
            const self = this;
            let datas = {
                together_id: self.selectTogether,
                id: self.id
            };
            console.log(datas);
            console.log(self.selectTogether);
            self.axios
                .post("/index.php/report/shareReportTemplateFile",datas)
                .then((result)=>{
                self.$Loading.hide();
                nprogressClose();
                if(result.data.code > 0)
                {
                    self.dialogShareVisible = false;
                }
                else
                {
                    self.$Tips({
                    // 消息提示内容
                    message: result.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 消失时间
                    displayTime: 1500
                    });return;
                }
            })
        },
        handleShare(row)
        {
            const self = this;
            self.dialogShareVisible = true;
            self.id = row.id;
            console.log(row);
        },
        vistualExpSelectChange() {},

        reportTemplateSelectClear() 
        {
            this.reportTemplateRemoteMethod("");
        },

        reportTemplateRemoteMethod(query) 
        {
            const self = this;
            if (query === "") 
            {
                this.options = [];
            }
            let data = {
                name: query,
            };
            self.loading = true;
            API.searchReportTemplateList(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.loading = false;
                            self.reportTemplateList = result.reportTemplateList;
                        }
                        // 关闭loading
                        // endLoading(startLoading());
                    })
                    .catch(()=>{
                        // 关闭loading
                        // endLoading(startLoading());
                    })
            // self.axios
            // .post("/index.php/report/searchReportTemplateList",data)
            // .then((result) => {
            //     self.loading = false;
            //     self.reportTemplateList = result.data.reportTemplateList;
            // })
            // .catch((err) => {});
        },

        markHandleBeforeUpload(file) 
        {
            const self = this;
            var array = file.name.split(".");
            if (array.length < 2) 
            {
                // Message.error("文件错误！");
                self.fileList = [];
                this.$Tips({
                    // 消息提示内容
                    message:"文件错误",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            }
            var ext = array[array.length - 1];
            if (ext != "xls" && ext != "xlsx") 
            {
                // Message.error("文件格式为xls，xlsx，请重试！");
                self.fileList = [];
                this.$Tips({
                    // 消息提示内容
                    message:"文件格式为xls，xlsx，请重试",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) 
            {
                // Message.error("上传头像图片大小不能超过 5MB!");
                self.fileList = [];
                this.$Tips({
                    // 消息提示内容
                    message:"上传头像图片大小不能超过 5MB",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            }
        },

        handleExceed(files, fileList) 
        {
            // Message.warning(
            // `当前限制选择 1 个文件，本次选择了 ${
            //     files.length
            // } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            // );
            this.$Tips({
                // 消息提示内容
                message:`当前限制选择 1 个文件，本次选择了 ${
                    files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'warning',
                button:[
                    {
                        type:1,
                        text:'知道了'
                    }
                ]
            })
        },

        beforeRemove(file, fileList) 
        {
            return this.$confirm(`确定移除 ${file.name}？`);
        },

        markHandleAvatarSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.file_url = res.url;
                self.file_path = res.path;
                // Message.success(res.msg);
            } 
            else 
            {
                // Message.error(res.msg);
            }
        },

        searchContent() 
        {
            const self = this;
            self.page = 1;
            // 查询列表
            self.getData();
            this.$router.push({
                path: "/teacher/reportTemplateFile",
                query: {
                    search: self.input
                }
            });
        },

        handleOpen(sign, title = "", index = "", row = "") 
        {
            const self = this;

            self.filename = '';
            self.file_url = ''; 
            self.file_path = ''; 
            self.report_template_id = '';
            self.report_template_file_id = '';
            self.fileList = [];

            self.handleSign = sign;
            self.dialogTitle = title;
            if (sign == "delete") 
            {
                self.dialogWidth = '30%';
                self.dialogVisible = true;
                self.report_template_file_id = row.id;
            } 
            else if ( sign == "add" || sign == "update" ) 
            {
                if (sign == "update") 
                {
                    self.report_template_file_id = row.id;
                    self.filename = row.filename;
                }
                self.dialogWidth = '50%';
                self.dialogVisible = true;
            }
            else if(sign == "download")
            {// 下载
                window.open(row.file_url);
            }
        },

        dialogFix() 
        {
            const self = this;
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            if (self.handleSign == "delete") 
            {
                if(self.report_template_file_id == '')
                {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                    // Message.warning("参数缺失，请重试！");return;
                    return this.$Tips({
                        // 消息提示内容
                        message:"参数缺失，请重试",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                }
                let da
                let data = {
                    report_template_file_id: self.report_template_file_id
                };
                // 请求数据
                API.deleteReportTemplateFile(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            self.report_template_file_id = "";
                            // Message.success(result.msg);
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            } 
            else if(self.handleSign == "update")
            {
                if(self.filename == '' || self.report_template_file_id == '')
                {
                    // 关闭loading
                    nprogressStart();
                    self.$Loading.hide();
                    // Message.warning("参数缺失，请重试！");return;
                    return this.$Tips({
                        // 消息提示内容
                        message:"参数缺失，请重试",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                }
                let data = {
                    filename: self.filename,
                    file_url: self.file_url,
                    file_path: self.file_path,
                    report_template_id: self.report_template_id,
                    report_template_file_id: self.report_template_file_id
                };
                // 请求数据
                API.updateReportTemplateFileData(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            self.title = "";
                            // Message.success(result.msg);
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
            else if(self.handleSign == "add")
            {
                if(self.filename == ''  || self.file_url == '' || self.file_path == '' || self.report_template_id == '')
                {
                    // 关闭loading
                    nprogressStart();
                    self.$Loading.hide();
                    // Message.warning("参数缺失，请重试！");return;
                    return this.$Tips({
                        // 消息提示内容
                        message:"参数缺失，请重试",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                }
                let data = {
                    filename: self.filename,
                    file_url: self.file_url,
                    file_path: self.file_path,
                    report_template_id: self.report_template_id,
                    report_template_file_id: self.report_template_file_id
                };
                // 请求数据
                API.updateReportTemplateFileData(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            self.title = "";
                            // Message.success(result.msg);
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
        },

        handleCurrentChange(val) 
        {
            const self = this;
            self.page = val;
            self.getData();
        },

        getData() 
        {
            const self = this;
            let data = {
                page: self.page,
                search: self.input
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getReportTemplateFileList(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.tableData = result.reportTemplateFileList;
                            self.dataTotal = result.count;
                            self.togetherList = result.togetherList;
                            // 路由传参
                            self.$router.push({
                                path: "/teacher/reportTemplateFile",
                                query: {
                                    search: self.input,
                                    page: self.page
                                }
                            });
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
        }
    }
};
