import { request } from "@/request/http";

export default {
  searchReportTemplateList: (data) => {
    return request("POST", `/index.php/report/searchReportTemplateList`, data);
  },

  deleteReportTemplateFile: (data) => {
    return request("POST", `/index.php/report/deleteReportTemplateFile`, data);
  },

  updateReportTemplateFileData: (data) => {
    return request("POST", `/index.php/report/updateReportTemplateFileData`, data);
  },

  getReportTemplateFileList: (data) => {
    return request("POST", `/index.php/report/getReportTemplateFileList`, data);
  },
};